// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-partner-js": () => import("./../../../src/pages/become-partner.js" /* webpackChunkName: "component---src-pages-become-partner-js" */),
  "component---src-pages-consultants-js": () => import("./../../../src/pages/consultants.js" /* webpackChunkName: "component---src-pages-consultants-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-company-js": () => import("./../../../src/pages/create-company.js" /* webpackChunkName: "component---src-pages-create-company-js" */),
  "component---src-pages-daf-timeshare-js": () => import("./../../../src/pages/daf-timeshare.js" /* webpackChunkName: "component---src-pages-daf-timeshare-js" */),
  "component---src-pages-financial-engineering-js": () => import("./../../../src/pages/financial-engineering.js" /* webpackChunkName: "component---src-pages-financial-engineering-js" */),
  "component---src-pages-forget-password-js": () => import("./../../../src/pages/forget-password.js" /* webpackChunkName: "component---src-pages-forget-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-details-js": () => import("./../../../src/pages/news-details.js" /* webpackChunkName: "component---src-pages-news-details-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-sarl-folder-js": () => import("./../../../src/pages/sarl-folder.js" /* webpackChunkName: "component---src-pages-sarl-folder-js" */),
  "component---src-pages-sarl-folder-resume-js": () => import("./../../../src/pages/sarl-folder-resume.js" /* webpackChunkName: "component---src-pages-sarl-folder-resume-js" */),
  "component---src-pages-select-project-js": () => import("./../../../src/pages/select-project.js" /* webpackChunkName: "component---src-pages-select-project-js" */),
  "component---src-pages-staff-provision-js": () => import("./../../../src/pages/staff-provision.js" /* webpackChunkName: "component---src-pages-staff-provision-js" */),
  "component---src-pages-support-offer-js": () => import("./../../../src/pages/support-offer.js" /* webpackChunkName: "component---src-pages-support-offer-js" */)
}

